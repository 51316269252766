
@font-face {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    src: url('./assets/Roboto/roboto-v20-latin-regular.eot');
    src: local('Roboto'), local('Roboto-Regular'),
    url('./assets/Roboto/roboto-v20-latin-regular.eot') format('embedded-opentype'),
    url('./assets/Roboto/roboto-v20-latin-regular.woff2') format('woff2'),
    url('./assets/Roboto/roboto-v20-latin-regular.woff') format('woff'),
    url('./assets/Roboto/roboto-v20-latin-regular.ttf') format('truetype'),
    url('./assets/Roboto/roboto-v20-latin-regular.svg') format('svg');
}
@font-face {
    font-family: 'microgramregular';
    src: url('./assets/microgrammaBold/microgram_regular-webfont.woff2') format('woff2'),
    url('./assets/microgrammaBold/microgram_regular-webfont.woff') format('woff');
    font-weight:normal;
}
@font-face {
    font-family: 'microgramma_dbold_extended';
    src: url('./assets/microgrammaBold/microgramma_d_extended_bold-webfont.woff2') format('woff2'),
    url('./assets/microgrammaBold/microgramma_d_extended_bold-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}
@font-face {
    font-family: 'robotobold';
    src: url('./assets/Roboto/roboto-bold-webfont.woff2') format('woff2'),
    url('./assets/Roboto/roboto-bold-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'robotoblack';
    src: url('./assets/Roboto/roboto.black-webfont.woff2') format('woff2'),
    url('./assets/Roboto/roboto.black-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}
body {
    margin: 0;
    font-family: "Roboto", Arial, Helvetica, sans-serif;
    overflow:auto;
}
code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

::-webkit-scrollbar {
    width: 7px;
    height: 7px;
}

::-webkit-scrollbar-track {
    -webkit-box-shadow: none;
}

::-webkit-scrollbar-thumb {
    background-color: darkgrey !important;
    outline: 1px solid slategrey;
    border:0 !important;
    border-radius: 0 !important;
}

input:focus,
select:focus,
textarea:focus {
    outline: none;
}

a {
    text-decoration: none;
}

.MuiListItemText-inset {
    padding-left: 20px !important;
}

.MuiListItem-gutters .MuiListItemText-inset span {
    font-size: 14px !important;

}

.MuiInputBase-root,
.MuiFormLabel-root {
    color: #222840 ;
    
}

.MuiInput-underline:before,
.MuiInput-underline:after {
    border-bottom: 0 !important;
}

.MuiDialog-paperWidthSm {
    max-width: 1000px !important;
    background: #fff !important;
}

.MuiInput-underline input {
    /* border-bottom:      solid 1px #222840 !important; */
}

.MuiInput-underline input.warning {
    border-bottom: 1px solid #e01100 !important;
}

table tbody tr td:last-child button {
    border-radius: 50%;
    padding: 0;
    margin: 6px;
    width: 32px;
    height: 32px;
}
table tbody tr td:last-child button svg{
    width:24px;
    height:24px;
    font-size:24px;
}
table tbody tr td {
    word-break: break-word;
    min-width: 135px;
    overflow: hidden;
    text-overflow: ellipsis;
}
table tbody tr td:first-child {
    min-width: inherit;
    white-space: nowrap;
}

.paddingTwoFive > div {
    box-shadow: none;
}

.simple-menu {
    top: 45px !important;
}

table tbody tr {
    height: inherit !important;
}

table tbody tr:first-child {
    height: 49px !important;
}

table thead tr th {
    min-width: 130px;
}

table thead tr th p {
    margin: 0;
}

table thead tr th:first-child {
    min-width: 35px;
}

.MuiDrawer-root.MuiDrawer-docked ul.MuiList-root div:nth-child(8) a div svg.MuiSvgIcon-root {
    transform: rotate(-45deg);
}

.crop {
    width: 100% !important;
    height: 100% !important;
    position: fixed !important;
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.3);
    margin: 0 !important;
    z-index: 9999;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.crop .croppie-container.img {
    background: #fff;
}

.crop .croppie-container.img .cr-slider-wrap {
    background: #ffff;
    margin: 0;
    padding: 15px;
    width: calc(100% - 30px);
    border-top: solid 1px #ccc;
}

.croppie-container .cr-viewport, .croppie-container .cr-resizer {
    box-shadow: none !important;
}

.crop button {
    background: #fff;
    color: #000;
    border: 0;
    padding: 8px 35px;
    font-size: 24px;
    cursor: pointer;
}

.error {
    border-bottom: 1px solid #e01100 !important;
    color: #e01100 !important;
}

.error:before {
    border-bottom: 1px solid #e01100 !important;
}

.error:hover:before {
    border-bottom: 1px solid #e01100 !important;
}

.hoverEffect:hover {
    background: #e01100;
}

.circle {
    fill: none;
    stroke-width: 2.8;
    animation: progress 1s ease-out forwards;
}

@keyframes progress {
    0% {
        stroke-dasharray: 0 100;
    }
}

.orange {
    stroke: #ff9f00;
}

.navyBlue {
    stroke: #0249DD;
}

.jazzberryJam {
    stroke: #AD1457;
}

.birdFlower {
    stroke: #DED714;
}

span#cke_1_bottom {
    border-top: 1px solid #4f5b7b;
}

span#cke_1_top {
    border-bottom: 1px solid #4f5b7b;
}

table.generateLegends {
    width: 100%;
}

table.generateLegends tr {
    display: table-cell;
    color: #000;
}

.VennDiagramChart svg text {
    margin-bottom: 10px;
}

.recharts-default-tooltip {
    border: 0 !important;
    padding: 5px 10px !important;
    border-radius: 3px;
    box-shadow: 0 0 2px #ccc;
}

path.domain {
    stroke: rgb(255, 127, 0);
}

.company-list table tr th:nth-child(3) {
    min-width: 320px;
}

.warning {
    border-bottom: solid 1px red !important;
}

.editor {
    min-width: 550px;
}

.ql-editor {
    max-height: 200px;
}
label.Mui-focused,
label.MuiFormLabel-filled {
    color: currentColor !important;
}
/* .loginPage label.Mui-focused,
.loginPage label.MuiFormLabel-filled {
    transform: translate(0px) scale(1) !important;
    color: #fff;
    background: #09142d;
    padding: 0 5px;
    z-index: 9;
    font-size: 12px;
    top: 0 !important;
}
.loginPage label.Mui-focused + div:after {
    border-color: #00b19d !important;
}

.loginPage label.Mui-focused + div input,
.loginPage label.MuiFormLabel-filled + div input {
    border-color: #fff !important;
}
label + div.Mui-focused:after,
label + div.MuiFormLabel-filled:after {
    border-color: currentColor !important;
} */
::-webkit-input-placeholder { /* Edge */
    color: #5D6483;
}
:-ms-input-placeholder { /* Internet Explorer 10-11 */
    color: #5D6483;
}
::placeholder {
    color: #5D6483;
}
.darkTheme input:-webkit-autofill,
.darkTheme input:-webkit-autofill:hover,
.darkTheme input:-webkit-autofill:focus,
.darkTheme input:-webkit-autofill:active  {
    -webkit-box-shadow: 0 0 0 30px #2d3c4300 inset !important;
}
.darkTheme input:-webkit-autofill {
    -webkit-text-fill-color: #fff !important;
    caret-color: #fff !important;
}
.lightTheme input:-webkit-autofill,
.lightTheme input:-webkit-autofill:hover,
.lightTheme input:-webkit-autofill:focus,
.lightTheme input:-webkit-autofill:active  {
    -webkit-box-shadow: 0 0 0 30px #f7f8f9 inset !important;
}
.lightTheme input:-webkit-autofill {
    -webkit-text-fill-color: #101111 !important;
    caret-color: #101111 !important;
}

div[role="dialog"] button span h4, div[role="dialog"] button span h6 {
    color: currentColor !important;
}

.Toastify > div > div{
    align-items: flex-start;
    min-height: inherit;
    padding: 20px 10px;
}
.Toastify > div > div .Toastify__toast-body{
    margin: 0;
    flex: 1 1;
    padding-top: 0
}
.Toastify > div > div button{
    align-self: flex-start;
    padding-top:0;
}
.rightLogo p{
    font-family: microgramma_dbold_extended, sans-serif;
}
.rightLogo p span{
    font-family: microgramregular, sans-serif;
}
.rightLogo div > span{
    font-family: robotoblack, sans-serif;
}
#printme { display: none;}
@media print {
#printme { display: block;}
body * {
visibility: hidden;
}
#printme, #printme * {
visibility: visible;
color: black;
}
#printme {
position: absolute;
left: 0;
top: 180px;
}

}
button{
    text-transform: capitalize !important;
}
button[class*="MuiButton-textPrimary-"]{
    color:#888;
}
button[class*="MuiPickersDay-daySelected"]{
    color:#2AA9E0;
}
/* .darkTheme::before{
    border-bottom: 1px solid #fff !important;
}
.darkTheme::after{
    border-bottom: 1px solid #fff !important;
} */

.darkThemes{
  background:#252735;
  /* height:100vh; */
}
.lightThemes{
  background:#f7f8f9;
  /* height:100vh; */
}