/* Notification.css (customize as needed) */

.notification-container {
  position: absolute;
  top: 30px;
  right: 10px;
  background-color: #5b5959;
  border: 1px solid #ccc;
  padding: 10px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  z-index: 999;
}

.notification-container h3 {
  margin-bottom: 10px;
}

.notification-container ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.notification-container li {
  margin-bottom: 5px;
}
